import styled from "styled-components";

export const Arrow = styled.div`
  &&& {
    position: relative;
    text-align: center;
    user-select: none;
    width: 137px;
    height: 76px;
    box-sizing: border-box;

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-weight: 600;
      font-size: 35px;
      color: ${(p) => p.theme.primary};
      line-height: 74px;
    }

    img {
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))
        drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.15));
        margin: 0;
    }
  }
`;
