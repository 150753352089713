import React from 'react'

import arrowNumber from "../../images/arrowNumber.svg";

import * as S from "./style"

export default function ArrowNumber({children}) {
    return (
        <S.Arrow>
            <img src={arrowNumber} alt="" />
            <span>{children}</span>
        </S.Arrow>
    )
}
