import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    
  html {
    font-size: 16px;
    font-family: 'Segoe UI', 'Verdana', sans-serif;
    background: ${(props) => props.theme.bgGray};;
    color: ${(props) => props.theme.text};
    --color-primary: rebeccapurple;
    --color-white: #FFFFFF;
    --shadow-primary: 0px 10px 20px rgba(0, 70, 215, 0.5);
    scroll-behavior: smooth;
  }

  body {
    position: relative;
    text-rendering: optimizeLegibility;
    font-family: 'Segoe UI', 'Verdana', sans-serif;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Segoe UI', 'Verdana', sans-serif;
  }

  button {
    font-family: 'Segoe UI', 'Verdana', sans-serif;
  }

  input {
    font-family: 'Segoe UI', 'Verdana', sans-serif;
  }

  p, a {
    line-height: 1.3;
  }

  .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  b {
    font-weight: 600;
  }

  ul {
    list-style-position: outside;
    list-style-type: disc;
    font-size: 16px;
    font-weight: 350;
    box-sizing: border-box;
    margin-bottom: 32px;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      margin: 0;
    }

    li {
      margin-left: 32px;
      line-height: 32px;

      &::marker {
        font-size: 80%;
        margin: 0;
      }
    }
  }
`;

export const theme = {
  text: "#0D0C22", //preto(#202124)
  primary: "#0046D7", //azul
  secondary: "#3CFF96", //verde
  degradeAzul: "linear-gradient(127.23deg, #0046D7 28.41%, #000093 83.13%)",
  degradeVerde: "linear-gradient(121.28deg, #3CFF96 -47.05%, #0046D7 164.43%)",
  degradeMisto: "linear-gradient(320deg, #0046D7 54.66%, #3CFF96 135.5%)",
  degradeMisto2:
    "linear-gradient(320deg, #3CFF96 10%, #0046D7 55%, #0046D7 100%)",
  degrade1: "linear-gradient(121deg, #3CFF96 -47%, transparent 164%) #0046D7",
  degrade2: "linear-gradient(121deg, #2FD5A5, transparent) #1381C3",

  shadow: {
    flat: "0px 0px 75px rgba(0, 0, 0, 0.15)",
    primary: "0px 10px 20px rgba(0, 70, 215, 0.5)",
    taxdashs: "0px 10px 20px rgba(255, 215, 0, 0.5);",
  },

  input: {
    bg: " #f0f4fe",
    border: "#dcdee0",
    shadow: {
      primary: "0px 8px 16px -8px rgba(0, 70, 215, 0.5)",
    },
  },

  hover: {
    primary: "#003299",
    secondary: "#00CC5E",
    laranja: "#FC3403",
    taxdashs: "#FFE600",
    degrade1: "#3FFF94",
    degrade2: "#3CFF96",
  },

  danger: "#d70024" /*vermelho*/,
  dangerLight: "#e34d66",
  dangerDark: "#970019",

  scrollbarModal: "#B9BDC2",

  bgGray: "#F7F9FA",

  textStrongGray: "#6D6C6B",
  textLightGray: "#909090",
  textGray: "#7E7E7E",
  textDarkGray: "#727272",
  textDarkerGray: "#5E5E5E",

  taxdashs: "#FFD700",

  //cores
  azul: "#0046D7",
  verde: "#3CFF96",
  verdeWpp: "#4DC247",
  branco: "#FFFFFF",
  laranja: "#FD6E4B",
  amarelo: "#FFFF00",
  amareloForte: "#FFD700",
  amareloEscuro: "#8F7100",

  textOnGreen: "#017C49",
  textOnYellow: "#8B6E00",

  //breakpoints
  bp: {
    mobile: "481px",
    tablet: "769px",
    smallDesktop: "970px",
    desktop: "1025px",
    bigDesktop: "1200px",
    veryBigDesktop: "1600px",
  },
};
