import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Testimonial from "../TestimonialCard";
import CarouselButton from "../CarouselButton";

import * as S from "./style";
import { AnimatePresence } from "framer-motion";
import { Interweave } from "interweave";

export default function TestimonialsWrapper({
  className,
  light,
  buttonColor,
  produto,
  twoColumns,
}) {
  const data = useStaticQuery(graphql`
    {
      allTestimonialsJson {
        nodes {
          taxcel {
            body
            empresa
            cargo
            nome
            foto {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          taxdashs {
            body
            cargo
            empresa
            nome
            foto {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          taxsheets {
            body
            cargo
            empresa
            nome
            foto {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const aux = twoColumns ? 2 : 3;

  const handleClickLeft = () => {
    if (step === 1) {
      setStep(step - 1);
      return;
    }
    if (step === 0) return;
    setStep(step - aux);
  };

  const handleClickRight = () => {
    if (step + aux >= testimonials.length) return;
    if (step + aux + 1 === testimonials.length) {
      setStep(step + 1);
      return;
    }
    setStep(step + aux);
  };

  let testimonials =
    produto === "taxdashs"
      ? data.allTestimonialsJson.nodes[1].taxdashs
      : produto === "taxsheets"
      ? data.allTestimonialsJson.nodes[2].taxsheets
      : data.allTestimonialsJson.nodes[0].taxcel;

  const [step, setStep] = useState(0);

  return (
    <S.Testimonials className={className} twoColumns={twoColumns}>
      <div className="cards">
        <AnimatePresence exitBeforeEnter>
          {testimonials.slice(step, step + aux).map((testimonial, index) => (
            <Testimonial
              light={light}
              key={testimonial.nome}
              foto={testimonial.foto.childImageSharp.gatsbyImageData}
              nome={testimonial.nome}
              cargo={testimonial.cargo}
              empresa={testimonial.empresa}
            >
              <Interweave content={testimonial.body} />
            </Testimonial>
          ))}
        </AnimatePresence>
      </div>
      <div className="buttons">
        <CarouselButton
          onClick={handleClickLeft}
          disabled={step === 0}
          color={buttonColor}
        />
        <CarouselButton
          disabled={step + aux >= testimonials.length}
          onClick={handleClickRight}
          color={buttonColor}
        />
      </div>
    </S.Testimonials>
  );
}
