import styled from "styled-components";
import { Container } from "../../../styles/UI";

export const Depoimentos = styled.section`
  background: ${(p) => p.theme.degradeAzul};
  padding: 64px 0;

  h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */
    letter-spacing: -0.01em;
    color: ${(p) => p.theme.branco};
    margin-bottom: 32px;
  }

  .buttons {
    text-align: center;
  }

  .paralax1 {
    display: none;
  }

  @media(min-width: ${(p) => p.theme.bp.veryBigDesktop}) {
    ${Container} {
      position: relative;

      .paralax1 {
        position: absolute;
        display: block;
        right: -310px;
        top: -480px;
      }
    }
  }

  
  @media (min-width: ${(p) => p.theme.bp.desktop}) {
    padding: 100px;

    h4 {
      font-size: 35px;
      line-height: 42px;
      margin-right: 40px;
      margin-top: 48px;
    }

    .buttons {
      text-align: left;
    }

    ${'' /* ${Container} {
      display: grid;
      grid-template-columns: 400px 1fr;
      grid-template-rows: auto 1fr;

      .cards {
        grid-row: 1 / span 2;
        grid-column: 2;
      }
    } */}
  }
`;
