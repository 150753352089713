import React from "react";

import adidas from '../../images/clientes/adidas.png';
import arcelorMittal from '../../images/clientes/ArcelorMittal.png';
import atacadao from '../../images/clientes/Atacadao.png';
import atem from '../../images/clientes/Atem.png';
import bridgestone from '../../images/clientes/Bridgestone.png';
import catupiry from '../../images/clientes/Catupiry.png';
import cpfl from '../../images/clientes/CPFL.png';
import ericsson from '../../images/clientes/Ericsson.png';
import eurofarma from '../../images/clientes/Eurofarma.png';
import henkel from '../../images/clientes/Henkel.png';
import huawei from '../../images/clientes/Huawei.png';
import jbs from '../../images/clientes/JBS.png';
import korin from '../../images/clientes/Korin.png';
import kpmg from '../../images/clientes/KPMG.png';
import loccitane from '../../images/clientes/Loccitane.png';
import marcopolo from '../../images/clientes/Marcopolo.png';
import merck from '../../images/clientes/Merck.png';
import mrv from '../../images/clientes/MRV.png';
import pandora from '../../images/clientes/Pandora.png';
import paodeacucar from '../../images/clientes/Pãodeacucar.png';
import pernodRicard from '../../images/clientes/PernodRicard.png';
import raiadrogasil from '../../images/clientes/RaiaDrogasil.png';
import raizen from '../../images/clientes/Raizen.png';
import sanofi from '../../images/clientes/Sanofi.png';
import schneider from '../../images/clientes/Shcneider.png';
import souzaCruz from '../../images/clientes/SouzaCruz.png';
import toyota from '../../images/clientes/Toyota.png';

import { useMediaQuery } from "react-responsive";

import Slider from "react-slick";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { GatsbyImage } from "gatsby-plugin-image"

import { graphql, useStaticQuery } from "gatsby";

import * as S from "./style";

export default function Clientes({ twoRows, bw, size }) {

const logosClientes = [
  { id: "1", image: adidas },
  { id: "2", image: arcelorMittal },
  { id: "3", image: atacadao },
  { id: "4", image: atem },
  { id: "5", image: bridgestone },
  { id: "6", image: catupiry },
  { id: "7", image: cpfl },
  { id: "8", image: ericsson },
  { id: "9", image: eurofarma },
  { id: "10", image: henkel },
  { id: "11", image: huawei },
  { id: "12", image: jbs },
  { id: "13", image: korin },
  { id: "14", image: kpmg },
  { id: "15", image: loccitane },
  { id: "16", image: marcopolo },
  { id: "17", image: merck },
  { id: "18", image: mrv },
  { id: "19", image: pandora },
  { id: "20", image: paodeacucar },
  { id: "21", image: pernodRicard },
  { id: "22", image: raiadrogasil },
  { id: "23", image: raizen },
  { id: "24", image: sanofi },
  { id: "25", image: schneider },
  { id: "26", image: souzaCruz },
  { id: "27", image: toyota }
];

  const isTablet = useMediaQuery({ query: "(min-width: 768px) and (max-width: 768px)" });

  const settings = {
    slidesToShow: isTablet ? 7 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 600,
    arrows: false,
  };


  const data = useStaticQuery(graphql`
    {
      clients: allClientesJson {
        nodes {
          id
          name
          logo {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  // const clientes = data.clients.nodes;
  // return (
  //   <S.Clientes $twoRows={twoRows} $bw={bw} $size={size}>
  //     {clientes.map((cliente) => (
  //       <GatsbyImage
  //         key={cliente.name}
  //         quality={100}
  //         image={cliente.logo.childImageSharp.gatsbyImageData}
  //         alt={`logo da empresa ${cliente.name}`}
  //       />
  //     ))}
  //   </S.Clientes>
 return (
  <div style={{ width: isTablet ? "810px" : "410px", height: "250px" }}>
    <Slider {...settings}>
      {logosClientes && logosClientes.map((item) => (
        <div key={item.id}>
          <img
            src={item.image}
            alt="logos de clientes da Taxcel"
            className="imageSlider"
            style={{display: 'flex', width: '75px', height: '46px', margin: '100px 20px', }}
          />
        </div>
      ))}
    </Slider>
    </div>
  );
}
